import React from "react"
import styled from 'styled-components'
import arrow_down from "../images/ArrowDownSmall.svg";

const Wrapper = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 2em;
  margin: auto;
  width: 1em;
  height: 1em;
  cursor: pointer;

  img{
    width: 100%;
  }
`;

const ArrowDown = (props) => {
  return  <Wrapper onClick={props.onClick}>
            <img src={arrow_down}/>
          </Wrapper>
}

export default ArrowDown
