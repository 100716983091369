import React from "react"
import styled from 'styled-components'
import Settings from '../settings.json';
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  background-color: #fbf9f7;

  @media (max-width: ${Settings.mobileBreakpoint}px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
`;

const SectionReverse = ({children}) => {
  return <Wrapper>
    {children}
  </Wrapper>
}

export default SectionReverse;
