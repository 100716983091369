import React from "react"
import Section from "../components/section"
import Half from "../components/half"
import Paragraph from "../components/paragraph";
import ImageBlock from "../components/imageblock";
import ImageZoom from "../components/image-zoom";
import ArrowDown from "../components/arrow-down";


const Phrase = ({ contenu, photo, titre }) => {
  return  <Section>
            <Half>
              <Paragraph title={titre} html={contenu.html}/>
              <ArrowDown/>
            </Half>
            <ImageBlock>
              <ImageZoom image={photo}/>
            </ImageBlock>
          </Section>
}

export default Phrase
