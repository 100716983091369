import React, { Suspense, lazy } from "react"
import styled from 'styled-components';
import Settings from '../settings.json';

const Wrapper = styled.div`
  width: 50%;
  height: 100vh;
  position: relative;
  overflow: hidden;

  @media (max-width: ${Settings.mobileBreakpoint}px) {
    width: 100%;
  }
`;

const Half = ({children}) => {
  return <Wrapper>
    {children}
  </Wrapper>
}

export default Half
