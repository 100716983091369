import React from "react"
import styled from 'styled-components'
import Settings from '../settings.json';

const Wrapper = styled.div`
  width: 50%;
  height: 100vh;
  position: relative;
  overflow: hidden;

  @media (max-width: ${Settings.mobileBreakpoint}px) {
    width: 100%;
    height: 50vh;
  }
`;

const ImageBlock = ({children}) => {
  return  <Wrapper>
            {children}
          </Wrapper>
}

export default ImageBlock
