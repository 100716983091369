import React, { Suspense, lazy } from "react";
import styled from 'styled-components';
import './carousel.css';
import GraphImg from "graphcms-image";
import { Fade } from 'react-slideshow-image';


const fadeProperties = {
  duration: 4000,
  transitionDuration: 300,
  infinite: true,
  indicators: false,
  arrows: false,
  pauseOnHover: true
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  & > div, .react-slideshow-container,
  .react-slideshow-fade-wrapper,
  .react-slideshow-fade-images-wrap
  {
    height: 100% !important;
  }
`;

 
function Carousel({pictures}) {
  return (
    <Wrapper>
      <Fade {...fadeProperties}>
        {pictures.map((picture) => (
          <GraphImg image={picture.image} maxWidth={800}/>
        ))}
      </Fade>
    </Wrapper>
  )
}

export default Carousel;