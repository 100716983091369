import React from "react";
import { graphql } from 'gatsby';
import SEO from "../components/seo";
import Sections from "../components/sections";
import '../components/layout.css';
import '../css/style.css';

const IndexPage = ({ data }) => (
  <main>
    <SEO title="Home" />
    <Sections sections={data.gcms.pages}/>
  </main>
)

export default IndexPage;

export const allPostsQuery = graphql`
query{
  gcms{
    pages(orderBy: position_ASC){
      id
      titre
      type
      photo{
        id
        url
        handle
        width
        height
      }
      slideshowPhotos {
        image{
          id
          url
          handle
          width
          height
        }
      }
      contenu {
        raw
        html
        markdown
        text
      }
    }
  }
}
`;
