import React from "react"
import styled from 'styled-components'
import Carousel from "../components/carousel";
import Half from "../components/half";
import Middle from "../components/Middle";
import letter from "../images/Letter.svg";
import ArrowDown from "../components/arrow-down";
import Section from "../components/section";
import ImageBlock from "../components/imageblock";
import Settings from '../settings.json';



const Letter = styled.div`
  position: absolute;
  right: 2em;
  top: 2em;
  width: 2.3em;
  height: 2.3em;
  cursor: pointer;

  img{
    width: 100%;
  }
`;

const Logo = styled.div`
  width: 100%;
  height: 100%;
`;

const LogoWrapper = styled.div`
  width: 100%;
  padding: 0 calc(3em + 5vw);

  @media (max-width: ${Settings.mobileBreakpoint}px) {
    padding: 0 calc(3em + 10vw);
  }
`;

const Home = (props) => {
  console.log('props', props);
  var logoUrl = props.contenu.raw.children.filter((node) => (node.type === 'image'))[0].src;
  
  
  return  <Section>
            <Half>
              <Letter>
                <a href="/#Contact">
                  <img src={letter}/>
                </a>
              </Letter>
              <ArrowDown/>
              <Logo>
                <Middle marginTop="-5vh">
                  <LogoWrapper>
                    <img src={logoUrl}/>
                  </LogoWrapper>
                </Middle>
              </Logo>
            </Half>
            <ImageBlock>
              <Carousel pictures={props.slideshowPhotos}/>
            </ImageBlock>
          </Section>;
}

export default Home
