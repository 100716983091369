import React, { Suspense } from "react";
import styled from 'styled-components';
import Scrollspy from 'react-scrollspy';
import Div100vh from 'react-div-100vh';
 

//Sections
import Contact from '../sections/contact'
import Home from '../sections/home'
import PhraseDroite from '../sections/phrase-droite'
import PhraseGauche from '../sections/phrase-gauche'

const Components = {
  'Home':Home,
  'PhraseDroite':PhraseDroite,
  'PhraseGauche':PhraseGauche,
  'Contact':Contact
}

const Section = styled.section`
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.nav`
  position: fixed;
  left: 1.5em;
  top: 1em;
  z-index: 99;
`;

const List = styled.nav`
  list-style-type:none;
  margin: 0;

  & a{
    height: 100%;
    width: 100%;
    display: block;
  }
`;

const ListItem = styled.li`
  height: 2em;
  width: 1em;
  margin: 0;
  text-align: center;
  cursor: pointer;

  &.inactive:hover div{
    background-color: #d2c6ac;
  }

  &.is-current div{
    background-color: #d2c6ac;
  }
`;

const Icon = styled.div`
  height: 8px;
  width: 8px;
  display: inline-flex;
  background-color: #F2ECE3;
  transition: all 100ms ease-in-out;
  border-radius: 100%;
`;

const SectionsWrapper = styled.div`
  width: 100%;
`;


class Sections extends React.Component{


  state = {
    activeHash:'Home'
  }


  render(){

    var self = this;
    var sections = this.props.sections.map((section) => (section.titre));
    return (
      <div>
        <Wrapper>
          <List>
            <Scrollspy items={sections} currentClassName="is-current">
                {sections.map((section) => {
                  return (
                    <ListItem>
                      <a href={'#' + section}><Icon/></a>
                    </ListItem>)
                })}
            </Scrollspy>
          </List>
        </Wrapper>
        <SectionsWrapper>
        {this.props.sections.map(function(section){
          const Component = Components[section.type];
          console.log('Section', section);
          return  <Section id={section.titre}>
                      <Component {...section}/>
                    </Section>
        })}
        </SectionsWrapper>
      </div>
    )
  }
}

export default Sections;
