import React from "react"
import styled from 'styled-components'
import GraphImg from "graphcms-image";

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;

  & .graphcms-image-outer-wrapper{
    transition: transform 400ms ease-in-out;
  }

  & .graphcms-image-outer-wrapper:hover{
    transform: scale(1.05);
  }
`;

const ImageCrop = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
`;

const ImageZoom = ({image}) => {
  return <ImageWrapper>
        <ImageCrop>
          <GraphImg image={image} maxWidth={800}/>
        </ImageCrop>
      </ImageWrapper>
}

export default ImageZoom
