import React from "react"
import styled from 'styled-components'
import Middle from "./Middle";
import Settings from '../settings.json';

const Text = styled.div`
  font-size: 3vw;
  line-height: 1.3;
  color: black;
  font-weight: 200;
  text-align: center;
  padding: calc(3rem + 6vw);

  @media (max-width: ${Settings.mobileBreakpoint}px) {
    font-size: 6vw;
  }
`;


const Paragraph = ({title, html}) => {
  console.log('->HTML', html);
  return  <>
            <Middle>
              <Text dangerouslySetInnerHTML={{__html:html }}/>
            </Middle>
          </>
}

export default Paragraph
