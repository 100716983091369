import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: table;
  height: 100%;
  width: 100%;
`;

const Content = styled.div`
  display: table-cell;
  vertical-align: middle;
`;

const Inner = styled.div`
  margin: auto;
  margin-top: ${(props) => props.marginTop};
  width: ${(props) => props.width};
`;




class Middle extends React.Component {
  render(){
    return(
      <Wrapper>
        <Content>
          <Inner width={this.props.width} marginTop={this.props.marginTop}>
            {this.props.children}
          </Inner>
        </Content>
      </Wrapper>
    )
  }
}

export default Middle;
