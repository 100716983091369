import React from "react"
import Section from "../components/section"
import styled from 'styled-components';
import Half from "../components/half";
import ImageZoom from "../components/image-zoom";
import Middle from "../components/Middle";
import icon from '../images/icon.svg'
import ImageBlock from "../components/imageblock";
import Settings from '../settings.json';

const ContactWrapper = styled.div`
  padding: calc(4rem + 3vw) calc(3rem + 3vw);
  width: 100%;
  height: 100%;
`;

const ContactInfos = styled.div`
  text-align: center;
  font-weight: 300;
  height: 100%;
  width: 100%;
  padding: calc(0rem + 3vw);
  background-color: #F2ECE3;
  position: relative;
`;


const IconPositioner = styled.div`
  position: absolute;
  top: 5em;
  width: 2em;
  left: 0;
  right: 0;
  margin: auto;
`;

const Paragraph = styled.div`
  font-size: 2vw;
  line-height: 1.6em;
  font-weight: 100;

  @media (max-width: ${Settings.mobileBreakpoint}px) {
    font-size: 5vw;
  }

  & h1{
    font-family: inherit;
    text-transform: uppercase;
    font-size: 0.8em;
    letter-spacing: 0.1em;
    font-weight: inherit;
    margin-bottom: .4em;
  }
`;

const LegalInfos = styled.div`
  position: absolute;
  top: 1.5rem;
  left: 0;
  right: 0;
  margin: auto;
  text-transform: uppercase;
  font-size: .6rem;
  letter-spacing: .1rem;
  color: #a5a199;
`;


const Contact = ({ contenu, photo }) => {
  return  <Section>
            <Half>
              <ContactWrapper>
                <ContactInfos>
                  <Middle>
                    <Paragraph dangerouslySetInnerHTML={{__html:contenu.html }}/>
                  </Middle>
                  <LegalInfos>
                    Nacarat sa
                  </LegalInfos>
                </ContactInfos>
              </ContactWrapper>
            </Half>
            <ImageBlock>
              <ImageZoom image={photo}/>
            </ImageBlock>
          </Section>
}

export default Contact
